<template>
  <div class="container_view">
    <img
      class="top_arrow_view"
      @click="toTop"
      src="../../assets/top_arrow_bg.png"
    />
    <div class="late_ly_sx_view">
      <div class="return_left_view" @click="returnPage"></div>
      <van-nav-bar class="nav_bar_view" />
    </div>
    <div
      class="late_ly_sx_view"
      style="height: 200px; z-index: 0; background-size: 100% 100%"
    >
      <!-- <div class="return_left_view" @click="returnPage"></div>
      <van-nav-bar class="nav_bar_view" /> -->
    </div>
    <div style="height: 100%; margin-bottom: 120px;">
    <!-- <div style="height: 100%; margin-bottom: 120px; background: #00ff00"> -->
      <van-list
        class="list_view"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell
          class="list_item_view"
          v-for="item in list"
          :key="item.id"
          @click="toGoodsDetail(item)"
        >
          <img v-show="(item.stockingTime == 1 || item.stockingTime == 2) && item.totalStock <= 0" class="torrmow_img" src="@/assets/ic_torrmow.png" alt />
          <img class="img_view" style="position: absolute;z-index: 10;" v-show="item.totalStock<= 0 && item.stockingTime >= 30" src="@/assets/shoujin_bg.png">
          <van-image class="img_view" :src="imgApp + item.image"></van-image>
          <span
            style="
              font-size: 13px;
              color: #3f3f3f;
              margin-left: 10px;
              display: block;
            "
            >{{ item.name }}</span
          >
          <span
            style="
              font-size: 12px;
              color: #999999;
              margin-left: 11px;
              display: block;
            "
            >{{ item.brandName }}</span
          >
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-left: 10px;
              margin-top: 10px;
              justify-content: space-between;
            "
          >
            <div class="price_view">
              <span style="color: #df3436; font-size: 12px">₱</span>
              <span style="color: #df3436; font-size: 18px">{{
                item.price
              }}</span>
            </div>
            <van-badge style="margin-right: 20px" max="100">
              <img
                style="width: 20px; height: 20px"
                src="../../assets/add_view_bg.png"
                alt=""
              />
            </van-badge>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import {
  goods_hotGoods,
  imgApp,
  module_getItemAndCartNum,
} from "@/service/api/index.js";

export default {
  data() {
    return {
      list: [],
      imgApp,
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 9999,
    };
  },
  mounted() {
    this.module_getItemAndCartNum();
  },
  methods: {
    onLoad() {
      // this.pageIndex++;
      // this.module_getItemAndCartNum();
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //   // 加载状态结束
      //   this.loading = false;
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    module_getItemAndCartNum() {
      module_getItemAndCartNum({
        id: 1,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.status == 200) {
          console.log(res)
          this.list = res.data.vos;
          // this.list = res.data.spuS;
          this.loading = false;
          this.finished = true;
        }
      });
    },

    toTop() {
      let timer = setInterval(function () {
        var distanceY =
          document.documentElement.scrollTop || document.body.scrollTop; //兼容
        if (distanceY == 0) {
          clearInterval(timer);
          return;
        }
        var speed = Math.ceil(distanceY / 1); //speed这个值从高变低，那么scrollTop就减得从快到慢，上回到顶部的速度就先快后慢
        console.log(speed);
        document.documentElement.scrollTop = distanceY - speed;
        // document.documentElement.scrollTop=distanceY-5;//如果给速度一个确定的值，那回到顶部的就匀速
      }, 16);
    },
    toGoodsDetail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id: item.id,
        },
      });
    },
    returnPage() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="less" scoped>
.torrmow_img {
  width: 70px;
  position: absolute;
  z-index: 10;
  left: 0;
}
.top_arrow_view {
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 20px;
  right: 6px;
  z-index: 2;
}
.container_view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (min-width: 768px) {
  .late_ly_sx_view {
    max-width: 540px;
  }
}
.late_ly_sx_view {
  width: 100%;
  height: 108px;
  position: fixed;
  z-index: 1;
  background-image: url("../../assets/late_ly_sx_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 108px;
  .return_left_view {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 55px;
    left: 20px;
  }
  .nav_bar_view {
    width: 100%;
    height: 108px;
    z-index: 2;
    background-image: url("../../assets/late_ly_sx_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 212px;
  }
}
.list_view {
  width: 100%;
  position: absolute;
  top: 108px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;
  .list_item_view {
    width: 48%;
    margin: 3px auto;
    background: white;
    box-shadow: 0px 10px 18px 0px rgba(153, 153, 153, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .img_view {
      width: 118.5px;
      height: 118.5px;
      margin-left: 5px;
    }
  }
}
.list_view:after {
  content: "";
  width: 50%;
  border: 1px solid transparent;
}
</style>